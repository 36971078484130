import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";
import ManagersService from "services/ManagersService";

export const initialState = {
    loading: false,
    manager: null,
    account: null,
    company: null,
    companyInfo: null,
    message: "",
    showMessage: false,
    redirect: "",
    token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk(
    "auth/login",
    async (data, { rejectWithValue }) => {
        const { email, password } = data;
        try {
            const response = await AuthService.login({ email, password });
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.error || "Error");
        }
    }
);
export const signInUser = createAsyncThunk(
    "auth/loginUser",
    async (data, { rejectWithValue }) => {
        const { email, password } = data;
        try {
            const response = await ManagersService.login({ email, password });
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.error || "Error");
        }
    }
);
export const forgetPassword = createAsyncThunk(
    "auth/forgetPassword",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthService.forgetPassword(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response || "Error");
        }
    }
);

export const signUp = createAsyncThunk(
    "auth/register",
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthService.register(data);
            return response;
        } catch (err) {
            return rejectWithValue(err.response?.data?.error || "Error");
        }
    }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
    const response = await FirebaseService.signOutRequest();
    localStorage.removeItem(AUTH_TOKEN);
    return response.data;
});
export const getUser = createAsyncThunk(
    "auth/currentUser",
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.getUser();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data.message || "Erreur");
        }
    }
);
export const getCompany = createAsyncThunk(
    "auth/getCompany",
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthService.getCompany(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data.message || "Erreur");
        }
    }
);
export const modifyAccount = createAsyncThunk(
    "auth/modifyAccount",
    async (accountData, { rejectWithValue }) => {
        try {
            const response = await AuthService.modifyAccount(accountData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);
export const modifyCompany = createAsyncThunk(
    "auth/modifyCompany",
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await AuthService.modifyCompany(id, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response || "Erreur");
        }
    }
);

export const signInWithGoogle = createAsyncThunk(
    "auth/signInWithGoogle",
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.loginInOAuth();
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const signInWithFacebook = createAsyncThunk(
    "auth/signInWithFacebook",
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthService.loginInOAuth();
            const token = response.data.token;
            localStorage.setItem(AUTH_TOKEN, token);
            return token;
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || "Error");
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = "/";
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = "";
            state.showMessage = false;
        },
        signOutSuccess: (state) => {
            state.loading = false;
            state.token = null;
            state.redirect = "/";
        },
        showLoading: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload;
        },
        hideLoading: (state, action) => {
            state.loading = false;
        },
        hideError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = "/";
                state.token = action.payload;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signInUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInUser.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = "/";
                state.token = action.payload;
            })
            .addCase(signInUser.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(forgetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(forgetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(forgetPassword.rejected, (state, action) => {
                state.error = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = "/";
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = "/";
            })
            .addCase(getUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.company = action.payload.companies[0];
            })
            .addCase(getUser.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(getCompany.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.companyInfo = action.payload;
            })
            .addCase(getCompany.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signUp.pending, (state) => {
                state.loading = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false;
                state.message = action.payload.message;
                state.showMessage = true;
            })
            .addCase(signUp.rejected, (state, action) => {
                state.message = action.payload;
                state.error = action.payload;
                state.showMessage = true;
                state.loading = false;
            }) // modify account
            .addCase(modifyCompany.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(modifyCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.company = action.payload;
            })
            .addCase(modifyCompany.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            }) // modify company
            .addCase(modifyAccount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(modifyAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.account = action.payload;
            })
            .addCase(modifyAccount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(signInWithGoogle.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInWithGoogle.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = "/";
                state.token = action.payload;
            })
            .addCase(signInWithGoogle.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signInWithFacebook.pending, (state) => {
                state.loading = true;
            })
            .addCase(signInWithFacebook.fulfilled, (state, action) => {
                state.loading = false;
                state.redirect = "/";
                state.token = action.payload;
            })
            .addCase(signInWithFacebook.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            });
    },
});

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess,
    hideError,
    hideLoading,
} = authSlice.actions;

export default authSlice.reducer;
