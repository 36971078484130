import {
    AUTH_PREFIX_PATH,
    REDIRECT_URL_KEY,
    UNAUTHENTICATED_ENTRY,
} from "configs/AppConfig";
import { protectedRoutes } from "configs/RoutesConfig";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
    const { token, user } = useSelector((state) => state.auth);
    const location = useLocation();

    const allowedRoutes = useMemo(
        () =>
            user?.manager === true
                ? protectedRoutes
                : protectedRoutes.filter((route) =>
                      user?.privileges?.find(
                          (privilege) =>
                              route.key.includes(privilege?.controller) &&
                              privilege?.privileged
                      )
                  ),
        [user]
    );

    if (!token)
        return (
            <Navigate
                to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
                replace
            />
        );

    if (
        user?.manager === true ||
        allowedRoutes.find((route) => location.pathname.includes(route.path))
    ) {
        return <Outlet />;
    }

    if (allowedRoutes.length === 0)
        return (
            <main
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
            >
                <h1 style={{ fontSize: "2rem" }}>403 Forbidden</h1>
            </main>
        );
    else return <Navigate to={allowedRoutes[0]?.path} replace />;
};

export default ProtectedRoute;
