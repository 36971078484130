import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { refresh } from "store/slices/authSlice";
import { signOutSuccess, signOut } from "store/slices/authSlice";
import store from "../store";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { notification } from "antd";

const unauthorizedCode = [400, 401, 403];

const service = axios.create({
    baseURL: "https://backend.win-guide.com/",
    timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = "authorization";

// API Request interceptor
service.interceptors.request.use(
    (config) => {
        // const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

        // if (jwtToken) {
        //   config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken;
        // }

        return config;
    },
    (error) => {
        // Do something with request error here
        notification.error({
            message: "Error",
        });
        Promise.reject(error);
    }
);

// API respone interceptor
service.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        let notificationParam = {
            message: "",
        };

        const prevRequest = error?.config;
        if (
            error?.response?.status === 404 &&
            error?.response?.data?.error === "Invalid or expired refresh token"
        ) {
            store.dispatch(signOut());
        }
        // else {
        //   if (
        //     error?.response?.status === 401 &&
        //     error?.response?.data?.message === "Token expired" &&
        //     !prevRequest?.sent
        //   ) {
        //     const newAccessToken = await store.dispatch(refresh());
        //     prevRequest.sent = true;
        //     prevRequest.headers[
        //       "Authorization"
        //     ] = `Bearer ${newAccessToken.payload}`;
        //     return service(prevRequest);
        //   }
        // }

        //   if (error.response.status === 404) {
        //     // Remove token and redirect
        //     // if (unauthorizedCode.includes(error.response.status)) {
        //     //   notificationParam.message = "Authentication Fail";
        //     //   notificationParam.description = "Please login again";
        //     //   localStorage.removeItem(AUTH_TOKEN);

        //     //   store.dispatch(signOutSuccess());
        //     // }

        //     notificationParam.message = "Not Found";
        //   }

        // if (error.response.status === 500) {
        //   notificationParam.message = "Internal Server Error";
        // }

        // if (error.response.status === 508) {
        //   notificationParam.message = "Time Out";
        // }

        // notification.error(notificationParam);

        return Promise.reject(error);
    }
);

export default service;
