import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";

const AuthService = {};

AuthService.login = function (data) {
    return fetch({
        url: "api/companies/login",
        method: "post",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });
};
AuthService.getUser = function () {
    return fetch({
        method: "get",
        url: "api/user/profil",
        data: {},
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
AuthService.getCompany = function (id) {
    return fetch({
        method: "get",
        url: `api/companies/getById/${id}`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
AuthService.modifyAccount = function (accountData) {
    return fetch({
        method: "put",
        url: "api/user/editMyProfil",
        data: accountData,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
AuthService.modifyCompany = function (id, data) {
    return fetch({
        method: "put",
        url: `api/companies/update/${id}`,
        data: data,
        headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
    });
};
AuthService.register = async (data) =>
    await fetch({
        url: "api/companies/register",
        method: "POST",
        data: data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

AuthService.forgetPassword = function (data) {
    return fetch({
        url: "api/companies/forget-password",
        method: "put",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });
};

AuthService.logout = function () {
    return fetch({
        url: "/auth/logout",
        method: "post",
    });
};

AuthService.loginInOAuth = function () {
    return fetch({
        url: "/auth/loginInOAuth",
        method: "post",
    });
};

export default AuthService;
